import sanity from "../client";

import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanity);

export const createURL = (source) => {
  return builder.image(source).quality(100).url();
};

export const FormatDate = (date) => {
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  let newDate = new Date(date);
  let writtenOut = newDate.toLocaleDateString("en-us", options);

  return writtenOut;
};

export const FormatDateWOYear = (date) => {
  const options = {
    month: "short",
    day: "numeric",
  };

  let newDate = new Date(date);
  let writtenOut = newDate.toLocaleDateString("en-us", options);

  return writtenOut;
};

export const FormatDateOnPostCard = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let newDate = new Date(date);

  let mm = monthNames[newDate.getMonth()];
  let dd = newDate.getDate();
  const yyyy = newDate.getFullYear();

  const formattedDate = mm + " " + dd + ", " + yyyy;

  return formattedDate;
};
