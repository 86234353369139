import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:id",
    name: "post",
    component: () => import("../views/post/_id.vue"),
    props: true,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  const matchedRoute = to.matched.find((route) => route.name === "post");
  const post = matchedRoute ? store.getters.postById(to.params.id) : null;

  if (post && post.title) {
    document.title = `Palmswap - ${post.title}`;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      if (post.excerpt) {
        metaDescription.setAttribute("content", post.excerpt);
      } else {
        metaDescription.setAttribute(
          "content",
          "Learn all about crypto on Palmswap Academy"
        );
      }
    }
  } else {
    document.title = "Palmswap Academy";
  }
  next();
});

export default router;
