<template>
  <div
    class="sm:w-[100%] md:w-[1118px] md:h-[379px] flex sm:flex-col md:flex-row sm:mt-5"
  >
    <div class="md:w-[100%] sm:w-100 md:mx-[10px] h-full z-1">
      <router-link :key="$route.path" :to="`/${firstPost._id}`">
        <img
          :src="createURL(firstPost.image)"
          v-if="firstPost.image"
          alt="alt"
          class="md:w-[637px] md:h-[379px] sm:w-[100%] rounded-[25px] object-cover"
        />
      </router-link>
    </div>

    <!-- mobile  date -->

    <div class="md:ml-[20px] flex flex-row md:mt-5 sm:mt-1 sm:flex md:hidden">
      <p class="text-graytext md:text-[14px] sm:text-[12px] leading-6">
        {{ FormatDateOnPostCard(firstPost._createdAt) }}
      </p>
      <p
        class="mx-[10px] mt-[-4px] text-graytext md:text-[14px] sm:text-[12px]leading-6 whitespace-nowrap"
      >
        .
      </p>
      <p
        class="text-graytext md:text-[14px] sm:text-[12px] leading-6 whitespace-nowrap"
      >
        {{ firstPost.readtime }} min read
      </p>
    </div>

    <!-- mobile date -->

    <div class="w-[100%] md:mx-[10px] flex items-center justify-center sm:mt-1">
      <div class="w-full md:mx-[10px] flex flex-col">
        <div class="categorys md:ml-[20px] flex flex-row">
          <div
            :class="`flex text-[12px] flex-row flex-wrap sm:py-2 sm:px-3 md:py-2 md:px-6 text-center rounded-[500px] whitespace-nowrap difficulty-${firstPost.difficulty}`"
          >
            <p class="align-middle mx-auto">
              {{ firstPost.difficulty }}
            </p>
          </div>
          <div
            class="ml-2 flex md:text-[14px] sm:text-[12px] flex-row flex-wrap py-2 px-6 bg-[#FFFFFF]/[10%] text-center rounded-[500px] whitespace-nowrap"
          >
            <p class="align-middle mx-auto">
              {{ firstPost.topic }}
            </p>
          </div>
        </div>

        <div class="md:ml-[20px] flex flex-row mt-5 sm:hidden md:flex">
          <p class="text-graytext text-[14px] leading-6">
            {{ FormatDateOnPostCard(firstPost._createdAt) }}
          </p>
          <p
            class="mx-[10px] mt-[-4px] text-graytext text-[14px] leading-6 whitespace-nowrap"
          >
            .
          </p>
          <p class="text-graytext text-[14px] leading-6 whitespace-nowrap">
            {{ firstPost.readtime }} min read
          </p>
        </div>
        <router-link :key="$route.path" :to="`/${firstPost._id}`">
          <div class="text-left flex md:mt-5 sm:mt-1 md:ml-[20px] s">
            <h2 class="font-bold md:text-[26px] sm:text-[16px]">
              {{ firstPost.title }}
            </h2>
          </div>

          <div class="text-left flex md:mt-5 sm:mt-1 md:ml-[20px]">
            <h2 class="text-[16px] text-[#dedede]">
              {{ firstPost.excerpt }}
            </h2>
          </div>
        </router-link>
        <router-link :key="$route.path" :to="`/${firstPost._id}`">
          <div class="flex mt-5 ml-[10px] md:ml-[20px] sm:hidden md:flex">
            <button
              class="text-[16px] text-black bg-white w-[147px] h-[50px] rounded-full"
            >
              Read more
            </button>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { FormatDateOnPostCard, createURL } from "../components/utils";
export default {
  props: {
    firstPost: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      FormatDateOnPostCard,
      createURL,
    };
  },
};
</script>

<style>
.difficulty-Beginner {
  background-color: rgba(0, 192, 117, 0.15);
  color: #00c075;
}
.difficulty-Intermediate {
  background-color: rgba(241, 203, 128, 0.15);
  color: #f1cb80;
}
.difficulty-expert {
  background-color: rgba(253, 64, 64, 0.15);
  color: #fd4040;
}
</style>
