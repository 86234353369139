<template>
  <div class="md:w-[352px] sm:w-[100%] md:mx-[10px] md:h-[391px] mt-5">
    <router-link :key="$route.path" :to="`/${three_posts._id}`">
      <div
        class="md:flex-1 flex md:flex-col sm:flex-row inline-block inline w-full"
      >
        <div class="sm:w-1/3 md:w-full">
          <img
            v-if="three_posts.image"
            :src="createURL(three_posts.image, 352, 198)"
            class="md:w-[352px] sm:min-w-[117px] sm:mr-2 sm:h-[66px] md:h-[198px] sm:rounded-[10px] md:rounded-[25px] object-cover"
          />
        </div>

        <div class="sm:w-2/3 md:w-full sm:ml-4 md:ml-0">
          <!-- mobile date -->
          <div class="flex flex-row mb-2 md:hidden sm:flex">
            <p class="text-graytext text-[14px] leading-6">
              {{ FormatDateOnPostCard(three_posts._createdAt) }}
            </p>
            <p
              class="mx-[10px] mt-[-4px] text-graytext text-[14px] leading-6 whitespace-nowrap"
            >
              .
            </p>
            <p class="text-graytext text-[14px] leading-6 whitespace-nowrap">
              {{ three_posts.readtime }} min read
            </p>
          </div>
          <!-- mobile date -->
          <h3
            class="text-white leading-[1.4rem] text-[16px] mb-2 w-auto text-left h-auto break-normal sm:flex md:hidden"
          >
            {{ three_posts.title }}
          </h3>

          <div
            class="flex flex row w-full items-center justify-between sm:mt-3 md:mt-0"
          >
            <div class="categorys md:mt-5 flex flex-row">
              <div
                :class="`flex text-[12px] flex-row flex-wrap sm:py-2 sm:px-3 md:py-2 md:px-6 text-center rounded-[500px] whitespace-nowrap difficulty-${three_posts.difficulty}`"
              >
                <p class="align-middle mx-auto">
                  {{ three_posts.difficulty }}
                </p>
              </div>
              <div
                class="ml-2 flex md:text-[14px] sm:text-[12px] flex-row flex-wrap py-2 px-6 bg-[#FFFFFF]/[10%] text-center rounded-[500px] whitespace-nowrap"
              >
                <p class="align-middle mx-auto">
                  {{ three_posts.topic }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-row mb-2 sm:hidden md:flex md:mt-2">
            <p class="text-graytext text-[14px] leading-6">
              {{ FormatDateOnPostCard(three_posts._createdAt) }}
            </p>
            <p
              class="mx-[10px] mt-[-4px] text-graytext text-[14px] leading-6 whitespace-nowrap"
            >
              .
            </p>
            <p class="text-graytext text-[14px] leading-6 whitespace-nowrap">
              {{ three_posts.readtime }} min read
            </p>
          </div>
          <h3
            class="text-white leading-[1.4rem] text-[16px] mb-2 w-auto text-left h-auto break-normal md:flex sm:hidden"
          >
            {{ three_posts.title }}
          </h3>

          <p
            class="text-[#dbdbdb] text-[16px] leading-[1.5rem] text-left inline md:flex sm:hidden"
          >
            {{ three_posts.excerpt }}
          </p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { FormatDateOnPostCard, createURL } from "../components/utils";

export default {
  props: {
    three_posts: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      FormatDateOnPostCard,
      createURL,
    };
  },
};
</script>

<style>
.difficulty-Beginner {
  background-color: rgba(0, 192, 117, 0.15);
  color: #00c075;
}
.difficulty-Intermediate {
  background-color: rgba(241, 203, 128, 0.15);
  color: #f1cb80;
}
.difficulty-expert {
  background-color: rgba(253, 64, 64, 0.15);
  color: #fd4040;
}
</style>
