const sanityClient = require('@sanity/client')
const client = sanityClient({
  projectId: process.env.VUE_APP_SANITY_PROJECT_ID,
  dataset: process.env.VUE_APP_SANITY_DATA_SET,
  apiVersion: '2021-03-25',
  useCdn: true,
})


export default client
