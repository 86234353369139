import { createStore } from "vuex";
import sanity from "../client";

export default createStore({
  state: {
    posts: [],
    total_posts: 0,
    first_post: [],
    three_posts: [],
    category: "",
  },
  getters: {
    postById: (state) => (id) => {
      return state.posts.find((post) => post._id === id);
    },
    first_post: (state) => state.first_post,
    three_posts: (state) => state.three_posts,
    random_posts: (state) => state.random_posts,

    posts: (state) =>
      state.posts.sort(
        (a, b) =>
          new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime()
      ),
  },
  mutations: {
    SET_POSTS(state, posts) {
      state.posts = posts;
    },

    SET_THREE_POSTS(state, three_posts) {
      state.three_posts = three_posts;
    },
    SET_FIRST_POST(state, first_post) {
      state.first_post = first_post;
    },
    SET_TOTAL_POSTS(state, total_posts) {
      state.total_posts = total_posts;
    },
    SET_RANDOM_POSTS(state, random_posts) {
      state.random_posts = random_posts;
    },
    INCREMENT_TOTAL_POSTS(state, increment = 1) {
      state.total_posts += increment;
    },
  },

  actions: {
    FetchPosts({ commit }, limit = null) {
      const fetchPosts = async () => {
        const query = `*[_type == 'post'] | order(_createdAt desc) ${
          limit ? `[0...${limit}]` : ""
        }`;

        await sanity.fetch(query).then((posts) => {
          // Sort posts by creation date
          const sortedPosts = posts.sort(
            (a, b) => new Date(b._createdAt) - new Date(a._createdAt)
          );

          // Get the second, third, and fourth posts
          const secondThirdFourthPosts = sortedPosts.slice(1, 4);

          // Set the state
          commit("SET_POSTS", posts);
          commit("SET_FIRST_POST", posts[0]);
          commit("SET_THREE_POSTS", secondThirdFourthPosts);
          commit(
            "SET_RANDOM_POSTS",
            sortedPosts.sort(() => Math.random() - 0.5)
          );
        });
      };

      fetchPosts();
    },

    FetchRandomPosts({ commit }) {
      const query2 = `*[_type == "post"]`;
      const fetchRandomPosts = async () => {
        await sanity.fetch(query2).then((posts) => {
          let shuffledPosts = posts.sort(() => Math.random() - 0.5);
          let limitedPosts = shuffledPosts.slice(0, 3);
          commit("SET_RANDOM_POSTS", limitedPosts);
        });
      };

      fetchRandomPosts();
    },

    AddNewPost({ commit }, post) {
      commit("SET_POSTS", [...this.state.posts, post]);
      commit("INCREMENT_TOTAL_POSTS");
    },
    RemovePost({ commit }, id) {
      commit(
        "SET_POSTS",
        this.state.posts.filter((p) => p._id !== id)
      );
      commit("INCREMENT_TOTAL_POSTS", -1);
    },
    LoadMorePosts({ commit }, limit = 5) {
      const query = `*[_type == "post"] {...} | order(_createdAt desc) [${
        this.state.posts.length
      }...${this.state.posts.length + limit}] `;

      const loadposts = async () => {
        await sanity.fetch(query).then((posts) => {
          commit("SET_POSTS", [...this.state.posts, ...posts]);
        });
      };

      loadposts();
    },
  },
});
