<template>
  <main class="home">
    <section
      class="flex flex-col w-full justify-center h-auto align-center z-10"
    >
      <Navbar @scroll-to-element="scrollToElement" />
      <div
        class="flex-col w-full justify-center items-center align-center mt-10"
      >
        <div class="text-center justify-items-center w-full flex-col">
          <h1 class="text-[32px] md:w-[500px] sm:w-[300px] mx-auto">
            Learn All About Tokens, Tokenization, and Fractionalized
            NFTs
          </h1>
          <h3 class="text-graytext sm:w-[300px] md:w-[500px] mx-auto mt-5">
            Become an expert in personal finance with our free and regularly
            updated eLearning platform.
          </h3>
        </div>

        <div
          class="search w-[301px] h-[45px] mr-auto ml-auto mt-10 align-middle"
        >
          <input
            v-model="inputValue"
            type="text"
            placeholder="Search..."
            @input="search"
            class="w-full bg-background h-full border border-box rounded-lg pl-4 focus:outline-none placeholder:text-graytext focus:caret-graytext focus:text-graytext"
          />
        </div>

        <div class="removeOnSearch flex flex-row w-[100%] mt-10 h-auto">
          <div
            class="line md:w-[1086px] w-[90%] 2xl:w-[57%] h-[2px] bg-box ml-auto mr-auto"
          ></div>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div
          class="latest_article removeOnSearch flex flex-row w-[100%] md:mt-10 sm:mb-10 md:mb-0 sm:mt-5"
          v-show="showDivs"
        >
          <div
            class="md:w-[1086px] w-[90%] 2xl:w-[57%] h-[2px] ml-auto mr-auto md:text-[26px] sm:text-[22px] font-bold"
          >
            <h2>Latest Articles</h2>
          </div>
        </div>
      </transition>

      <div class="flex justify-items-center w-full">
        <div
          class="flex flex-row h-auto sm:w-[90%] md:w-[1118px] md:justify-start sm:justify-center flex-wrap mx-auto"
        >
          <transition name="fade" mode="out-in">
            <div
              v-show="showDivs"
              class="removeOnSearch flex flex-row h-auto sm:w-[100%] md:w-[1118px] md:mt-10 md:justify-start sm:justify-center flex-wrap mx-auto"
            >
              <firstPost :first-post="firstPost" />
              <ThreePosts
                v-for="(three_posts, i) in three_posts"
                :key="i"
                :three_posts="three_posts"
              />
            </div>
          </transition>

          <transition name="fade" mode="out-in">
            <div class="flex flex-row w-[100%] sm:mt-10 md:mt-[100px] h-auto">
              <div
                v-show="showDivs"
                ref="targetElement"
                class="line removeOnSearch md:w-[1100px] w-[100%] 2xl:w-[57%] h-[2px] bg-box ml-auto mr-auto"
              ></div>
            </div>
          </transition>

          <transition name="fade" mode="out-in">
            <div
              v-show="showDivs"
              class="removeOnSearch flex flex-col w-full h-auto mt-10"
            >
              <div class="explore flex flex-col w-[100%]">
                <div
                  class="md:w-[1100px] sm:w-[100%] 2xl:w-[57%] ml-auto mr-auto text-[26px] font-bold"
                >
                  <h2>Explore</h2>
                </div>
                <div
                  class="explore flex inline-flex flex-wrap md:w-[1100px] w-[100%] 2xl:w-[57%] ml-auto mr-auto mt-5"
                >
                  <h3 class="text-[16px] color-white sm:mt-2 md:mt-1 mr-5 py-2">
                    Topics:
                  </h3>
                  <button
                    class="text-[14px] sm:m-2 flex-row flex-wrap md:mr-2 py-2 px-6 text-center rounded-[500px] whitespace-nowrap bg-[#FFFFFF]/[.10] text-white"
                    @click="selectedTopic = ''"
                  >
                    All
                  </button>

                  <button
                    class="text-[14px] sm:mt-2 sm:mb-2 sm:mr-4 md:mr-0 flex-row flex-wrap md:ml-2 py-2 px-6 text-center rounded-[500px] whitespace-nowrap bg-[#FFFFFF]/[.10] text-white"
                    v-for="topic in topics"
                    :key="topic"
                    @click="selectedTopic = topic"
                  >
                    {{ topic }}
                  </button>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade" mode="out-in">
            <div
              v-show="showDivs"
              class="removeOnSearch flex flex-col w-full h-auto mb-10"
            >
              <div class="explore flex flex-col w-[100%]">
                <div
                  class="topics flex inline-flex flex-wrap md:w-[1100px] w-[100%] 2xl:w-[57%] ml-auto mr-auto mt-5"
                >
                  <h3 class="text-[16px] color-white sm:mt-2 md:mt-0 mr-5 py-2">
                    Difficulty:
                  </h3>
                  <button
                    class="text-[14px] sm:m-2 md:m-0 flex-row flex-wrap md:mr-2 py-2 px-6 text-center rounded-[500px] whitespace-nowrap bg-[#FFFFFF]/[.10] text-white"
                    @click="selectedDifficulty = ''"
                  >
                    All
                  </button>

                  <button
                    v-for="difficulty in difficulties"
                    :key="difficulty"
                    :class="`text-[14px] sm:mt-2 sm:mb-2 sm:mr-4 md:m-0 flex-row flex-wrap md:mr-2 py-2 px-6 text-center rounded-[500px] whitespace-nowrap  difficulty-${difficulty}`"
                    @click="selectedDifficulty = difficulty"
                  >
                    {{ difficulty }}
                  </button>
                </div>
              </div>
            </div>
          </transition>

          <noResults v-if="filteredPosts.length === 0" class="mt-0" />

          <PostCard
            v-for="(post, i) in filteredPosts"
            :key="i"
            :post="post"
            @select-difficulty="updateSelectedDifficulty"
            @select-topic="updateSelectedTopic"
          />
        </div>
      </div>


      <footer-comp v-on:buttonInFooter="updateSearch($event), activeButton()" />
    </section>
  </main>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import Navbar from "../components/Navbar.vue";
import FooterComp from "../components/footer-comp.vue";
import firstPost from "../components/firstPost.vue";
import ThreePosts from "../components/ThreePosts.vue";
import PostCard from "../components/PostCard.vue";
import noResults from "../components/noResults.vue";

export default {
  components: {
    PostCard,
    FooterComp,
    Navbar,
    noResults,
    ThreePosts,
    firstPost,
  },

  data() {
    return {
      search: "",
      category: "",
      showLoad: true,
      showDivs: true,
      selectedDifficulty: "",
      selectedTopic: "",
      inputValue: "",
      topics: [
        "Crypto",
        "DeFi",
        "Blockchain",
        "Trading",
        "Privacy",
        "NFT",
        "Mining",
        "Metaverse",
      ],
      difficulties: ["Beginner", "Intermediate", "Expert"],
    };
  },

  watch: {
    selectedTopic() {
      this.resetDifficulty();
    },
  },
  computed: {
    filteredPosts() {
      let filteredPosts = this.posts;

      if (this.selectedDifficulty && this.selectedTopic) {
        filteredPosts = filteredPosts.filter(
          (post) =>
            post.difficulty.toLowerCase() ===
              this.selectedDifficulty.toLowerCase() &&
            post.topic.toLowerCase() === this.selectedTopic.toLowerCase()
        );
      } else if (
        (this.inputValue !== "" && this.selectedTopic) ||
        (this.inputValue !== "" && this.selectedDifficulty)
      ) {
        this.removeOnSearch();
        this.resetDifficulty();
        filteredPosts = filteredPosts.filter((post) =>
          post.title.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      } else if (this.selectedDifficulty) {
        filteredPosts = filteredPosts.filter(
          (post) =>
            post.difficulty.toLowerCase() ===
            this.selectedDifficulty.toLowerCase()
        );
      } else if (this.selectedTopic) {
        filteredPosts = filteredPosts.filter(
          (post) =>
            post.topic.toLowerCase() === this.selectedTopic.toLowerCase()
        );
      } else if (this.inputValue === "") {
        //display latest artciles when search is empty
        this.showWhenEmpty();
      } else if (this.inputValue !== "") {
        //Searchbar
        this.removeOnSearch();

        filteredPosts = filteredPosts.filter((post) =>
          post.title.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      }

      return filteredPosts;
    },
  },

  methods: {
    //update difficulty on PostCard
    updateSelectedDifficulty(difficulty) {
      this.selectedDifficulty = difficulty;
    },
    //update topic on PostCard
    updateSelectedTopic(topic) {
      this.selectedTopic = topic;
    },

    resetDifficulty() {
      this.selectedDifficulty = "";
    },

    resetTopic() {
      this.selectedTopic = "";
    },

    removeOnSearch() {
      this.showDivs = false;
    },

    showWhenEmpty() {
      this.showDivs = true;
    },

    //chain the function to be called when input is not empty

    updateSearch(newSearch) {
      this.search = newSearch;
    },
    scrollToElement() {
      const targetElement = this.$refs.targetElement;
      const targetPosition = targetElement.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    },
  },

  setup() {
    const store = useStore();
    const posts = computed(() => {
      return store.getters.posts;
    });
    const firstPost = computed(() => {
      return store.getters.first_post;
    });

    const three_posts = computed(() => {
      return store.getters.three_posts;
    });

    onMounted(() => {
      store.dispatch("FetchPosts");
    });

    return {
      posts,
      three_posts,
      firstPost,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.difficulty-Beginner {
  background-color: rgba(0, 192, 117, 0.15);
  color: #00c075;
}
.difficulty-Intermediate {
  background-color: rgba(241, 203, 128, 0.15);
  color: #f1cb80;
}
.difficulty-Expert {
  background-color: rgba(253, 64, 64, 0.15);
  color: #fd4040;
}
</style>
