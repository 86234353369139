<template>
  <div class="md:w-[352px] sm:w-[100%] md:mx-[10px] md:h-[450px] mt-5">
    <div
      class="md:flex-1 flex md:flex-col sm:flex-row inline-block inline w-full"
    >
      <router-link :key="$route.path" :to="`/${post._id}`">
        <div class="sm:w-1/3 md:w-full">
          <img
            v-if="post.image"
            :src="createURL(post.image)"
            class="md:w-[352px] sm:min-w-[117px] sm:h-[66px] md:h-[198px] sm:rounded-[10px] md:rounded-[25px] object-cover"
          />
        </div>
      </router-link>

      <div class="sm:w-2/3 md:w-full sm:ml-4 md:ml-0">
        <!-- mobile date -->
        <router-link :key="$route.path" :to="`/${post._id}`">
          <div class="flex flex-row mb-2 md:hidden sm:flex">
            <p class="text-graytext text-[14px] leading-6">
              {{ FormatDateOnPostCard(post._createdAt) }}
            </p>
            <p
              class="mx-[10px] mt-[-4px] text-graytext text-[14px] leading-6 whitespace-nowrap"
            >
              .
            </p>
            <p class="text-graytext text-[14px] leading-6 whitespace-nowrap">
              {{ post.readtime }} min read
            </p>
          </div>
        </router-link>

        <!-- mobile date -->

        <router-link :key="$route.path" :to="`/${post._id}`">
          <h3
            class="text-white leading-[1.4rem] text-[16px] mb-2 w-auto text-left h-auto break-normal sm:flex md:hidden"
          >
            {{ post.title }}
          </h3>
        </router-link>
        <router-link :key="$route.path" :to="`/${post._id}`">
          <div
            class="flex flex row w-full items-center justify-between sm:mt-3 md:mt-0"
          >
            <div class="categorys md:mt-5 flex flex-row">
              <div
                @click="selectDifficulty"
                :class="`flex text-[12px] cursor-pointer flex-row flex-wrap sm:py-2 sm:px-3 md:py-2 md:px-6 text-center rounded-[500px] whitespace-nowrap difficulty-${post.difficulty}`"
              >
                <p class="align-middle mx-auto">
                  {{ post.difficulty }}
                </p>
              </div>
              <div
                @click="selectTopic"
                class="ml-2 cursor-pointer flex md:text-[14px] sm:text-[12px] flex-row flex-wrap py-2 px-4 bg-[#FFFFFF]/[10%] text-center rounded-[500px] whitespace-nowrap"
              >
                <p class="align-middle mx-auto">
                  {{ post.topic }}
                </p>
              </div>
            </div>
          </div>
        </router-link>

        <router-link :key="$route.path" :to="`/${post._id}`">
          <div class="flex flex-row mb-2 sm:hidden md:flex md:mt-2">
            <p class="text-graytext text-[14px] leading-6">
              {{ FormatDateOnPostCard(post._createdAt) }}
            </p>
            <p
              class="mx-[10px] mt-[-4px] text-graytext text-[14px] leading-6 whitespace-nowrap"
            >
              .
            </p>
            <p class="text-graytext text-[14px] leading-6 whitespace-nowrap">
              {{ post.readtime }} min read
            </p>
          </div>

          <h3
            class="text-white leading-[1.4rem] text-[16px] mb-2 w-auto text-left h-auto break-normal md:flex sm:hidden"
          >
            {{ post.title }}
          </h3>

          <p
            class="text-[#dbdbdb] text-[16px] leading-[1.5rem] text-left inline md:flex sm:hidden"
          >
            {{ post.excerpt }}
          </p>
        </router-link>
      </div>
    </div>
    <!-- </router-link> -->
  </div>
</template>

<script>
import { FormatDateOnPostCard, createURL } from "../components/utils";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    difficulty: {
      type: String,
      required: true,
    },
  },

  methods: {
    selectDifficulty() {
      this.$emit("select-difficulty", this.post.difficulty);
    },
    selectTopic() {
      this.$emit("select-topic", this.post.topic);
    },
  },

  setup() {
    return {
      FormatDateOnPostCard,
      createURL,
    };
  },
};
</script>

<style>
.difficulty-Beginner {
  background-color: rgba(0, 192, 117, 0.15);
  color: #00c075;
}
.difficulty-Intermediate {
  background-color: rgba(241, 203, 128, 0.15);
  color: #f1cb80;
}
.difficulty-expert {
  background-color: rgba(253, 64, 64, 0.15);
  color: #fd4040;
}
</style>
